import styled from 'styled-components';
import { breakpoint } from 'theme';

import WpImage from 'components/atoms/wp-image';

export const StyledWrapper = styled.div<{ padding?: string; background?: boolean }>`
  ${breakpoint.md} {
    padding: ${({ padding }) => padding || 'unset'};
    background: ${({ background, theme }) => background && `${theme.colors.grayscaleGray0}`};
  }
`;

export const StyledWpImage = styled(WpImage)`
  min-height: 216px;
  max-height: 484px;
`;

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.wrapperXxl};
  margin: auto;
`;
