import styled from 'styled-components';
import { breakpoint } from 'theme';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<{ isGray?: boolean }>`
  background-color: ${({ theme, isGray }) =>
    isGray ? theme.colors.grayscaleGray0 : theme.colors.white};
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `36px ${theme.gap.sm}`};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
`;

export const StyledTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.gap.sm};
  margin-top: 8px;
  ${breakpoint.md} {
    flex-direction: row;
    gap: 60px;
    margin-top: 16px;
    margin-bottom: ${({ theme }) => theme.gap.lg};
  }
`;

export const StyledAdventages = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    flex-direction: row;
    gap: 30px;
  }
`;

export const StyledAdventage = styled.div<{ isGray?: boolean }>`
  padding: ${({ theme, isGray }) => (isGray ? theme.gap.sm : 'none')};
  background-color: ${({ theme, isGray }) => (isGray ? theme.colors.white : 'none')};
  display: flex;
  align-items: center;
  gap: 24px;
  box-shadow: 0 2px 8px rgba(32, 37, 50, 0.08), 0 2px 4px rgba(32, 37, 50, 0.03);

  ${breakpoint.md} {
    padding: ${({ theme, isGray }) => (isGray ? theme.gap.md : 'none')};
    flex-direction: column;
    gap: 18px;
    width: 255px;
    align-items: flex-start;
  }
`;

export const StyledTitle = styled(Typography)`
  ${breakpoint.md} {
    width: 525px;
  }

  strong {
    text-decoration-color: transparent;

    ${breakpoint.md} {
      text-decoration-color: ${({ theme }) => theme.colors.primary50};
    }
  }
`;

export const StyledDescription = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    padding-block: 12px;
    width: 525px;
    padding-bottom: 0;
    align-self: flex-end;
  }
`;

export const StyledDescriptionLinkButton = styled(Button)`
  padding-bottom: 5px;

  ${breakpoint.md} {
    margin-top: 16px;
    padding-bottom: 0;
  }
`;
