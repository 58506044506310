import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import AdventagesSection, { AdventagesSectionType } from 'components/organisms/adventages-section';
import AssesmentDescriptionSection, {
  AssesmentDescriptionSectionType,
} from 'components/organisms/assesment-description-section';
import { ConsultingCooperationSectionType } from 'components/organisms/consulting-cooperation-section';
import FullBanner, { FullBannerType } from 'components/organisms/full-banner';
import OurMissionSection, {
  TextBannerWithImageType,
} from 'components/organisms/our-mission-section';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import IndustriesBanner, { IndustriesBannerType } from 'components/molecules/industries-banner';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import { ButtonType } from 'components/atoms/button';

import { theme } from '../theme';

type DataProps = {
  offersHRConsultingDetails: {
    template: {
      offersHRConsultingDetailsData: {
        bannerQuaternary: IndustriesBannerType;
        hcdDescription: AssesmentDescriptionSectionType;
        advantageLinkButton: ButtonType;
        projectRealizations: ConsultingCooperationSectionType;
        whenValue: TextBannerWithImageType;
        advantagesSection: AdventagesSectionType;
        fullBanner: FullBannerType;
        otherServicesBlock: TextBannerCardType;
      };
    };
  };
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
};

const OffersConsultingDetailsPage = ({
  pageContext,
  data: {
    offersHRConsultingDetails: {
      template: {
        offersHRConsultingDetailsData: {
          bannerQuaternary,
          hcdDescription,
          advantageLinkButton,
          whenValue,
          advantagesSection,
          fullBanner,
          otherServicesBlock,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <IndustriesBanner {...bannerQuaternary} pageContext={pageContext} isWhite />
    <AssesmentDescriptionSection {...hcdDescription} marginBottom />

    <OurMissionSection firstSection={whenValue} clearBg />
    <AdventagesSection
      isGray
      {...advantagesSection}
      variantHeading="title8"
      advantageLinkButton={advantageLinkButton}
    />
    <FullBanner {...fullBanner} background padding="0 0 82px" />
    <LetsTalkBanner {...letsTalkBanner} reverseHalfBg />
    <TextBannerCardSection
      {...otherServicesBlock}
      textColumn
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      variantHeading="title7"
    >
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => (
            <BannerCard {...item} margin="0" maxWidth="340px" key={item.text} />
          ))
        : null}
    </TextBannerCardSection>
  </Layout>
);

export const query = graphql`
  query OffersHRConsultingDetailsPage($id: String, $commonComponentsSlug: String) {
    offersHRConsultingDetails: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OffersHRConsultingDetails {
          offersHRConsultingDetailsData {
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            hcdDescription {
              advantagesList {
                listText: text
                icon {
                  name
                }
              }
              heading
              headingAdvantages
              text
              textAdvantages
            }
            advantageLinkButton: linkButton {
              icon {
                name
              }
              label
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
            projectRealizations {
              bannerImage {
                ...WpImageFragment
              }
              heading
              text
              companiesList {
                image {
                  ...WpImageFragment
                }
                link {
                  ariaLabel
                }
              }
              linkButton {
                buttonLabel: label
                link {
                  external {
                    url
                    linkTitle: title
                  }
                }
              }
            }
            fullBanner {
              bannerImage {
                ...WpImageFragment
              }
            }
            whenValue {
              heading
              subtitle
              text: description
              image {
                ...WpImageFragment
              }
              bulletsList {
                text
              }
              linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
              }
            }
            advantagesSection {
              advantagesList {
                heading
                icon {
                  name
                }
                text
              }
              heading
              subTitle
              text
            }
            otherServicesBlock {
              subTitle
              heading
              text
              cardList: servicesList {
                heading
                text
                icon {
                  name
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersConsultingDetailsPage;
