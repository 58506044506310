import React from 'react';

import { ButtonType } from 'components/atoms/button';
import Icon, { IconType } from 'components/atoms/icon';
import Typography, { TypographyVariantType } from 'components/atoms/typography';

import {
  StyledAdventage,
  StyledAdventages,
  StyledContent,
  StyledDescription,
  StyledDescriptionLinkButton,
  StyledTextSection,
  StyledTitle,
  StyledWrapper,
} from './adventages-section.styles';

export type AdventagesSectionType = {
  subTitle: string;
  heading: string;
  text: string;
  advantageLinkButton: ButtonType;
  isGray?: boolean;
  variantHeading?: TypographyVariantType;
  advantagesList: {
    heading: string;
    icon: IconType;
  }[];
};

const AdventagesSection = ({
  heading,
  subTitle,
  text,
  advantagesList,
  variantHeading,
  advantageLinkButton,
  isGray = false,
}: AdventagesSectionType) => (
  <StyledWrapper isGray={isGray}>
    <StyledContent>
      <Typography variant="title3" component="h3" color="gray40" html={subTitle} />
      <StyledTextSection>
        <StyledTitle html={heading} variant={variantHeading || 'title1'} />
        <div>
          <StyledDescription variant="body1" html={text} />
          <StyledDescriptionLinkButton {...advantageLinkButton} variant="underline" />
        </div>
      </StyledTextSection>
      {advantagesList ? (
        <StyledAdventages>
          {advantagesList.map(({ heading: IconText, icon }) => (
            <StyledAdventage isGray={isGray} key={IconText}>
              <Icon {...icon} />
              <Typography variant="title3" component="h3">
                {IconText}
              </Typography>
            </StyledAdventage>
          ))}
        </StyledAdventages>
      ) : null}
    </StyledContent>
  </StyledWrapper>
);

export default AdventagesSection;
