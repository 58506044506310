import React from 'react';

import { WpImageType } from 'components/atoms/wp-image';

import { StyledContainer, StyledWpImage, StyledWrapper } from './full-banner.styles';

export type FullBannerType = {
  bannerImage: WpImageType;
  background?: boolean;
  padding?: string;
};

const FullBanner = ({ bannerImage, background, padding }: FullBannerType) => (
  <StyledWrapper background={background} padding={padding}>
    <StyledContainer>
      <StyledWpImage {...bannerImage} />
    </StyledContainer>
  </StyledWrapper>
);

export default FullBanner;
